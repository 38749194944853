import { render, staticRenderFns } from "./SampleComparison.vue?vue&type=template&id=18f6097c&scoped=true&"
import script from "./SampleComparison.vue?vue&type=script&lang=js&"
export * from "./SampleComparison.vue?vue&type=script&lang=js&"
import style0 from "./SampleComparison.vue?vue&type=style&index=0&id=18f6097c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f6097c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAutocomplete,VBreadcrumbs,VBtn,VChip,VCol,VIcon,VImg,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSkeletonLoader})
