<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-breadcrumbs :items="breadcrumb" class="pl-3">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end align-center">
        <v-btn
          small
          depressed
          class="indigo white--text br-6 px-4"
          :loading="exportLoading"
          :disabled="testOrdersSelected.filter(ord => ord.id).length === 0"
          @click="exportToExcel"
        >
          {{ $t('content.export_excel') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters class="ml-3 mt-3">
      <v-col v-if="titleLoading" cols="12" class="title">
        <v-skeleton-loader type="heading" max-width="300px" />
        <v-skeleton-loader type="text" max-width="200px" class="mt-3" />
      </v-col>
      <v-col v-else cols="12" class="title">
        <h3>{{ $t('title.sample_comparison') }}</h3>
        <span>{{ $t('content.sc_t_order') }} : {{ title.name || '-' }}</span>
      </v-col>
    </v-row>

    <div class="d-flex ml-3 mt-5 overflow-auto ">
      <div class="left-labels">
        <!-- #region label -->
        <div class="labels mr-5" v-if="!informationLoading">
          <v-row
            no-gutters
            v-for="(info, index) in Object.keys(information)"
            :key="index"
            class="labels-inner d-flex align-center text-no-wrap"
          >
            <v-col cols="12">{{ information[info] }}</v-col>
          </v-row>
        </div>
        <div class="labels mr-5" v-else>
          <v-row no-gutters v-for="index in 5" :key="index" class="labels-inner d-flex align-center">
            <v-col cols="12">
              <v-skeleton-loader type="text" />
            </v-col>
          </v-row>
        </div>
        <!-- #endregion -->
      </div>

      <!-- #region average -->
      <div class="content">
        <div class="d-flex align-center justify-center mb-4 average">
          <div class="text-center">
            <SvgIcon icon="icon-average" style="margin-bottom: 10px;" /><br />
            {{ $t('content.average') }}<br />
          </div>
        </div>
        <v-chip
          color="orange lighten-5"
          small
          label
          :ripple="false"
          text-color="grey darken-4"
          class="d-flex align-center justify-center py-4 average-info"
        >
          <v-icon left color="orange">
            mdi-information
          </v-icon>
          {{
            testOrdersLength > 0
              ? $t(testOrdersLength > 1 ? 'content.sc_smpls' : 'content.sc_smpl', { value: testOrdersLength })
              : $t('content.sc_n_smpl')
          }}
        </v-chip>
        <v-row no-gutters class="labels content-detail average-bg mrt-a" v-if="averageLoading || Object.keys(average).length > 0">
          <v-col cols="12">
            <v-row
              no-gutters
              v-for="(label, index) in Object.keys(information)"
              :key="index"
              class="labels-inner d-flex align-center"
            >
              <v-col cols="12" class="text-right" v-if="!averageLoading">
                {{ average[label] || '-' }}
              </v-col>
              <v-col cols="12" class="text-right" v-else>
                <v-skeleton-loader type="text" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="labels content-detail average-bg mrt-a" v-else>
          <v-col cols="12" class="d-flex align-center justify-center no-sample-text">
            {{ $t('content.no_avg_data') }}
          </v-col>
        </v-row>
      </div>
      <!-- #endregion -->
      <div class="d-flex content-container" id="comparisonContent">
        <!-- #region content -->
        <template v-if="testOrdersSelected">
          <div class="content" v-for="(item, index) in testOrdersSelected" :key="index">
            <v-btn
              fab
              x-small
              width="22px"
              height="22px"
              :depressed="!item.id"
              :loading="item.loading_image && item.loading_results"
              color="red darken-4"
              class="remove-icon white--text"
              @click="remItem(item._rand, item.id)"
            >
              <v-icon class="ma-0">mdi-close</v-icon>
            </v-btn>
            <v-img v-if="!item.loading_image && item.image" height="140" class="rounded mb-4" :src="item.image" />
            <div class="d-flex align-center justify-center mb-4 no-sample" v-else-if="!item.id">
              <div class="text-center">
                <SvgIcon icon="icon-no-sample" /><br /><br />
                {{ $t('content.no_smpl_slctd') }}
              </div>
            </div>
            <div v-else-if="!item.loading_image && !item.image" class="d-flex align-center justify-center mb-4 no-image">
              <div>
                <SvgIcon icon="no-image" />
                <h4>{{ $t('content.no_image_0') }}</h4>
              </div>
            </div>
            <v-skeleton-loader v-else-if="item.loading_image" type="image" width="140px" height="140px" class="mb-4" />
            <v-autocomplete
              v-model="item.id"
              outlined
              dense
              single-line
              :items="testOrdersFilter(item.id)"
              :item-text="item => sampleText(item)"
              item-value="id"
              :placeholder="$t('content.select_sample')"
              :hide-details="true"
              :disabled="item.id && item.id !== null ? true : false"
              background-color="#fff"
              class="br-6"
              @change="testOrdersChange(item._rand, item.id)"
              :loading="(item.loading_image && item.loading_results) || testOrdersLoading"
            >
              <template #item="{ item }">
                <v-list-item-content class="item-select">
                  <div class="d-flex item-select-content">
                    <v-list-item-title>
                      <b>{{ item.company }}</b>
                    </v-list-item-title>
                    <v-list-item-title>
                      <b>{{ `- ${item.idfl_number}` }}</b>
                    </v-list-item-title>
                  </div>
                  <v-list-item-subtitle v-text="item.short_sample_identification" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-row
              no-gutters
              class="labels content-detail contain-detail-h mrt-b"
              :class="{ 'content-detail-overflow': contentOverflow }"
              v-if="!item.loading_results && item.results"
            >
              <v-col cols="12">
                <v-row
                  no-gutters
                  v-for="(label, index) in Object.keys(information)"
                  :key="index"
                  class="labels-inner d-flex align-center"
                >
                  <v-col cols="12" class="text-right" v-if="item.results">{{ item.results[label] }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="labels content-detail contain-detail-h mrt-b"
              :class="{ 'content-detail-overflow': contentOverflow }"
              v-else-if="item.loading_results"
            >
              <v-col cols="12">
                <v-row
                  no-gutters
                  v-for="(label, index) in Object.keys(information)"
                  :key="index"
                  class="labels-inner d-flex align-center"
                >
                  <v-col cols="12" class="text-right">
                    <v-skeleton-loader type="text" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="labels content-detail contain-detail-h mrt-b"
              :class="{ 'content-detail-overflow': contentOverflow }"
              v-else
            >
              <v-col cols="12" class="d-flex align-center justify-center no-sample-text">
                {{ $t('content.no_data') }}
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-else>
          <div class="content">
            <v-skeleton-loader type="image" width="140px" height="140px" class="mb-4" />
            <v-skeleton-loader type="image" width="140px" height="40px" />
            <v-row
              no-gutters
              class="labels content-detail contain-detail-h mrt-c"
              :class="{ 'content-detail-overflow': contentOverflow }"
            >
              <v-col cols="12">
                <v-row
                  no-gutters
                  v-for="(label, index) in Object.keys(information)"
                  :key="index"
                  class="labels-inner d-flex align-center"
                >
                  <v-col cols="12" class="text-right">
                    <v-skeleton-loader type="text" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </template>
        <!-- #endregion -->

        <!-- #region add content -->
        <div class="content" v-if="sameLength">
          <div class="d-flex align-center justify-center mb-4 add-sample">
            <div class="text-center">
              {{ $t('content.add_smpl_cmpr') }}<br /><br />
              <v-btn color="primary" depressed fab x-small dark @click="addItem">
                <v-icon class="add-icon">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <!-- #endregion -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { utilityMixin, downloadMixin } from '@/mixins';

export default {
  name: 'SampleComparison',
  mixins: [utilityMixin, downloadMixin],
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      contentOverflow: false,
      exportLoading: false,
      titleLoading: false,
      averageLoading: false,
      informationLoading: false,
      testOrdersLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      params: 'SampleComparison/params',
      title: 'SampleComparison/title',
      information: 'SampleComparison/information',
      testOrders: 'SampleComparison/testOrders',
      testOrdersSelected: 'SampleComparison/testOrdersSelected',
      average: 'SampleComparison/average',
    }),
    breadcrumb() {
      return [
        {
          text: this.$t('title.result_management'),
          disabled: false,
          to: { name: 'ResultManagement' },
          exact: true,
        },
        {
          text: this.$t('title.sample_comparison'),
          disabled: true,
        },
      ];
    },
    sameLength() {
      const { results } = this.testOrders;
      const test_orders = this.params?.test_orders?.split(',');
      return results && test_orders ? !(test_orders.length >= results.length) : true;
    },
    testOrdersLength() {
      return this.testOrdersSelected.filter(ord => ord.id).length;
    },
  },
  watch: {
    params(val) {
      if (val.test_id && val.test_orders) {
        const _test_orders = this.testOrdersSelected.map(ord => ord.id?.toString());
        const testOrders = val.test_orders
          .split(',')
          .map(ord => ord.toString())
          .filter(ord => !_test_orders.includes(ord));
        Promise.all([
          testOrders.forEach(id => {
            this.$store.dispatch('SampleComparison/getTestOrderProducedResults', {
              id,
            });
          }),
        ]).finally(() => {
          this.averageLoading = true;
          this.$store
            .dispatch('SampleComparison/getAverage')
            .catch(err => this.$toast.error(err.message))
            .finally(() => {
              this.averageLoading = false;
              setTimeout(() => {
                this.scrollToEnd();
                this.checkOverflow();
              }, 1000);
            });
        });
      }
    },
    '$route.query': {
      immediate: true,
      deep: true,
      handler(qry) {
        if (!qry.test_orders) this.$store.commit('SampleComparison/SET_AVERAGE', []);

        this.$store.commit('SampleComparison/SET_PARAMS', {
          test_id: this.id,
          test_orders: qry.test_orders ?? null,
        });
      },
    },
  },
  methods: {
    addItem() {
      this.$store.commit('SampleComparison/ADD_TEST_ORDER', {
        _rand: Math.random(),
      });
      this.scrollToEnd();
      this.checkOverflow();
    },
    remItem(_rand, id) {
      const test_orders = this.params?.test_orders?.split(',').map(ord => parseInt(ord)) ?? [];
      const index = test_orders.findIndex(ord => ord === id);
      if (index > -1) test_orders.splice(index, 1);
      this.$router
        .replace({
          query: {
            test_orders: test_orders.length > 0 ? test_orders.toString() : null,
          },
        })
        .catch(() => {});
      this.$store.commit('SampleComparison/DEL_TEST_ORDER', { _rand });
      this.checkOverflow();
    },
    testOrdersChange(_rand, id) {
      this.$store.commit('SampleComparison/EDIT_TEST_ORDER', { _rand, loading: true });
      const test_orders = this.params?.test_orders?.split(',') ?? [];
      test_orders.push(id);
      this.$store.dispatch('SampleComparison/getTestOrderProducedResults', { _rand, id }).finally(() => {
        this.$router.replace({
          query: { test_orders: test_orders.toString() },
        });
      });
    },
    scrollToEnd() {
      setTimeout(() => {
        let objDiv = document.getElementById('comparisonContent');
        objDiv.scroll({ left: objDiv.scrollWidth, behavior: 'smooth' });
      }, 300);
    },
    checkOverflow() {
      setTimeout(() => {
        let objDiv = document.getElementById('comparisonContent');
        this.contentOverflow = objDiv.scrollWidth > objDiv.clientWidth;
      }, 300);
    },
    exportToExcel() {
      this.exportLoading = true;
      const test_orders = this.testOrdersSelected
        .filter(ord => ord.id)
        .map(ord => ord.id)
        .toString();
      this.$store
        .dispatch('File/getData', {
          data: '/sample_workbench/api/comparison/export/',
          params: { test_id: this.id, test_orders },
        })
        .then(blob => {
          this.downloadMixin_download(blob, {
            name: `IDFL ${this.title.name} Comparison ${this.utilityMixin_formatSDateTime(new Date())}.xlsx`,
          });
        })
        .finally(() => (this.exportLoading = false));
    },
    testOrdersFilter(id) {
      const { results } = this.testOrders;
      const _orders_selected = this.testOrdersSelected.map(ord => ord.id);
      const _index = _orders_selected.findIndex(ord => ord === id);
      if (_index > -1) _orders_selected.splice(_index, 1);
      return results.filter(({ id }) => !_orders_selected.includes(id)) ?? [];
    },
    getTestOrder(id) {
      const { results } = this.testOrders;
      const order = results.find(ord => ord.id === id);
      return order ?? {};
    },
    sampleText(item) {
      return `${item.idfl_number} - ${item.company} - ${item.short_sample_identification}`;
    },
  },
  created() {
    this.titleLoading = true;
    this.informationLoading = true;
    this.testOrdersLoading = true;
    this.$store.dispatch('SampleComparison/getTestTitle', this.id).finally(() => (this.titleLoading = false));
    this.$store.dispatch('SampleComparison/getTestInformation', this.id).finally(() => (this.informationLoading = false));
    this.$store.dispatch('SampleComparison/getTestOrders', this.id).finally(() => (this.testOrdersLoading = false));
  },
  destroyed() {
    this.$store.commit('SampleComparison/RESET_ALL');
  },
};
</script>

<style lang="scss" scoped>
.title {
  h3,
  span {
    color: #16325c;
  }

  h3 {
    font-size: 22px;
  }

  span {
    font-size: 15px;
    font-weight: 400;
  }
}

.labels {
  background: #f2f7fb;
  border: solid 1px #f2f7fb;
  border-radius: 8px;
  padding: 12px;
  font-size: 15px;
  color: #16325c;
  min-width: 316px;
  max-width: 500px;

  .labels-inner {
    min-height: 36px;
    padding: 0 5px 0 5px;
  }
}

.left-labels {
  margin-top: 217px;
  margin-bottom: 23px;
}

.content-container {
  overflow-x: auto;
  scrollbar-color: #acc8dd #fff;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #acc8dd;
  }
}

.content {
  position: relative;
  width: 140px;
  margin-right: 19px;
}

.content-detail {
  background: transparent !important;
  border: solid 1px #73acd9;
  min-width: 140px;
}

.contain-detail-h {
  height: calc(100% - 240px);
}

.content-detail-overflow {
  height: calc(100% - 240px) !important;
}

.no-sample {
  height: 140px;
  background: #f7f7f3;
  border-radius: 4px;
  font-size: 12px;
  color: #8495ad;
}

.remove-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
}

.no-sample-text {
  font-size: 14px;
  color: #8495ad;
}

.add-sample {
  width: 140px;
  height: 140px;
  color: #16325c;
  font-size: 13px;
  padding-top: 3px;
  border-radius: 4px;

  .add-icon {
    font-size: 24px;
  }

  &:hover {
    background: #f2f7fb;
  }
}

.average {
  height: 140px;
  background: #f2f7fb;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  color: #0060af;
}

.average-bg {
  background: #f2f7fb !important;
}

.average-info {
  width: 100%;
  height: 40px;
  border-radius: 8px !important;
}

.br-6 {
  border-radius: 6px;
}

.mrt-a {
  height: calc(100% - 240px);
  margin-top: 21px;
}

.mrt-c {
  margin-top: 34px;
}

.mrt-b {
  margin-top: 21px;
}

.no-image {
  border-radius: 4px;
  width: 140px;
  height: 140px;
  padding: 20px;
  background: #f7f7f3;

  div {
    text-align: center;

    h4 {
      margin-top: 15px;
      color: #6a7b94;
      font-weight: 300;
      font-size: 12px;
    }
  }
}

.item-select {
  max-width: 300px;
  position: relative;

  .item-select-content {
    width: 100%;
  }
}

::v-deep .v-select__slot > .v-label,
::v-deep .v-select__slot > input {
  font-size: 13px;
}

::v-deep ::placeholder {
  font-size: 13px;
}
</style>
